const calculateLevel = (x, d) => {
  if (!x) {
    return 0
  }
  if (x.parent === null) {
    return 0
  }

  const y = d.find((i) => i.id == x.parent?.id)

  return calculateLevel(y, d) + 1
}

const pushToSorted = (array, element) => {
  const newArray = []
  array.forEach((x) => {
    if (x.id === element.id) {
      return
    }

    newArray.push(x)
    if (element.parent?.id == x.id) {
      newArray.push(element)
    }
  })

  return newArray
}

export const processCustomParamlists = (data) => {
  if (!data) {
    return data
  }

  data = data.map((x) => {
    x.level = calculateLevel(x, data)

    return x
  })

  data.sort((x1, x2) => x1.level - x2.level)

  let sorted = data.filter((x) => x.level == 0)
  data.forEach((x) => {
    if (x.level == 0) {
      return
    }
    sorted = pushToSorted(sorted, x)
  })

  return sorted
}

export const parseCustomParamlistsAsTree = (data) => {
  if (data === null) {
    return null
  }

  const rootList = data.filter((x) => x.parent === null)

  const findChildren = (item) => {
    const children = data.filter((x) => x.parent?.id === item.id)

    return children.map((x) => {
      return { ...x, children: findChildren(x) }
    })
  }

  return rootList.map((x) => {
    return { ...x, children: findChildren(x) }
  })
}
