import cx from "classnames"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import FormField from "src/components/FormField"
import Input from "src/components/FormField/Input"
import Select from "src/components/FormField/Select"
import Loading from "src/components/Loading"
import { processCustomParamlists } from "src/helpers/configCustomParamlists"
import { getCustomParamlist, getCustomParamlistItem } from "src/services/customParamlists.service"
import useDeepCompareEffect from "use-deep-compare-effect"
import style from "./style.module.scss"

const SingleDatePicker = dynamic(() => import("src/components/SingleDatePicker"))

export default function CustomParamlistFieldset({
  scope,
  containerStyle = {},
  initialValues = {},
  cols = 4,
  positionLabel = "up",
  labelClassName = null,
  className = null,
  onChange = () => {},
  disabled = false
}) {
  const [paramlists, setParamlists] = useState(null)
  const [selectedChoices, setSelectedChoices] = useState(initialValues)
  const [paramlistChoices, setParamlistChoices] = useState({})

  useDeepCompareEffect(() => {
    setSelectedChoices(initialValues)
  }, [initialValues])

  const fetchParamlistChoices = (x, parentId = null) => {
    setParamlistChoices((prev) => {
      return { ...prev, [x.id]: null }
    })

    getCustomParamlistItem(x.id, parentId).then(({ data }) => {
      setParamlistChoices((prev) => {
        return { ...prev, [x.id]: data }
      })
    })
  }

  useEffect(() => {
    getCustomParamlist({ scope }).then(({ data }) => {
      const p = processCustomParamlists(data)
      setParamlists(p)
      p.filter((x) => x.level === 0).forEach((x) => {
        fetchParamlistChoices(x)
      })
    })
  }, [])

  const getParamlistChoices = (paramlistId) => {
    return paramlistChoices[paramlistId]
  }

  const findParamlistChildren = (paramlistId) => {
    return paramlists?.filter((x) => x.parent?.id == paramlistId)
  }

  const handleChange = (paramlistId) => {
    return ({ id }) => {
      setSelectedChoices((prev) => {
        return { ...prev, [paramlistId]: id }
      })

      const children = findParamlistChildren(paramlistId)
      children?.forEach((x) => {
        fetchParamlistChoices(x, id)
        handleChange(x)({ id: null })
      })

      if (typeof onChange === "function") {
        onChange(paramlistId, id)
      }
    }
  }

  const handleChangeInput = (paramlistId) => {
    return (value) => {
      setSelectedChoices((prev) => {
        return { ...prev, [paramlistId]: value }
      })

      if (typeof onChange === "function") {
        onChange(paramlistId, value)
      }
    }
  }

  const formatChoices = (choices, paramlistId) => {
    if (!Array.isArray(choices)) {
      return choices
    }

    return [{ id: null, label: "", selected: !selectedChoices[paramlistId] }].concat(
      choices.map((x) => {
        return { id: x.id, label: x.name, selected: selectedChoices[paramlistId] == x.id }
      })
    )
  }

  const renderField = (paramlist) => {
    switch (paramlist.fieldtype) {
      case "list":
        return (
          <FormField
            label={paramlist.name}
            labelClassName={labelClassName}
            positionLabel={positionLabel}
            required={false}
          >
            {renderSelect(paramlist)}
          </FormField>
        )
      case "input_number":
        return (
          <FormField
            label={paramlist.name}
            labelClassName={labelClassName}
            positionLabel={positionLabel}
            required={false}
          >
            <Input
              initialValue={selectedChoices[paramlist.id]}
              cleanZero={false}
              type='number'
              onChange={handleChangeInput(paramlist.id)}
            />
          </FormField>
        )
      case "input_currency":
        return (
          <FormField
            label={paramlist.name}
            labelClassName={labelClassName}
            positionLabel={positionLabel}
            required={false}
          >
            <Input
              initialValue={selectedChoices[paramlist.id]}
              cleanZero={false}
              isNumber='currency'
              onChange={handleChangeInput(paramlist.id)}
            />
          </FormField>
        )
      case "input_text":
        return (
          <FormField
            label={paramlist.name}
            labelClassName={labelClassName}
            positionLabel={positionLabel}
            required={false}
          >
            <Input
              initialValue={selectedChoices[paramlist.id]}
              cleanZero={false}
              onChange={handleChangeInput(paramlist.id)}
            />
          </FormField>
        )
      case "input_date":
        return (
          <FormField
            label={paramlist.name}
            labelClassName={labelClassName}
            positionLabel={positionLabel}
            required={false}
            border={false}
          >
            <SingleDatePicker
              value={selectedChoices[paramlist.id]}
              onChange={([date, dateStr]) => {
                handleChangeInput(paramlist.id)(dateStr)
              }}
            />
          </FormField>
        )
    }
  }

  const renderSelect = (paramlist) => {
    const choices = getParamlistChoices(paramlist.id)
    return (
      <Select
        initialOptions={formatChoices(choices, paramlist.id)}
        disabled={typeof choices === "undefined" || disabled}
        selectionChange={handleChange(paramlist.id)}
      />
    )
  }

  if (paramlists === null) {
    return <Loading type='PulseLoader' centered={false} />
  }

  if (paramlists.length === 0) {
    return null
  }

  return (
    <div className={cx(style.fieldset, className)} style={containerStyle}>
      {paramlists.map((paramlist) => {
        return (
          <div key={paramlist.id} className={cx(style.field, style[`field${cols}`])}>
            {renderField(paramlist)}
          </div>
        )
      })}
    </div>
  )
}
