import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getCustomParamlist = authValidationMiddleware(async (filters = {}) => {
  const response = await axiosApiInstance.get(`/paramlists/custom_paramlist/`, { params: filters })

  return response.data
})

export const createCustomParamlist = authValidationMiddleware(async (values) => {
  const response = await axiosApiInstance.post(`/paramlists/custom_paramlist/`, values)

  return response.data
})

export const updateCustomParamlist = authValidationMiddleware(async (id, payload) => {
  const response = await axiosApiInstance.put(`/paramlists/custom_paramlist/${id}/`, payload)

  return response.data
})

export const deleteCustomParamlist = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/custom_paramlist/${id}/`)

  return response.data
})

export const getCustomParamlistItem = authValidationMiddleware(async (paramlistId, parentId = null) => {
  const params = {}
  if (parentId) {
    params.parent = parentId
  }
  const response = await axiosApiInstance.get(`/paramlists/custom_paramlist/${paramlistId}/items/`, { params })

  return response.data
})

export const createCustomParamlistItem = authValidationMiddleware(async (paramlistId, values) => {
  const response = await axiosApiInstance.post(`/paramlists/custom_paramlist/${paramlistId}/items/`, values)

  return response.data
})

export const updateCustomParamlistItem = authValidationMiddleware(async (paramlistId, id, values) => {
  const response = await axiosApiInstance.put(`/paramlists/custom_paramlist/${paramlistId}/items/${id}/`, values)

  return response.data
})

export const deleteCustomParamlistItem = authValidationMiddleware(async (paramlistId, id) => {
  const response = await axiosApiInstance.delete(`/paramlists/custom_paramlist/${paramlistId}/items/${id}/`)

  return response.data
})

export const getCustomModelField = authValidationMiddleware(async (modelo, modelo_id, params = {}) => {
  const response = await axiosApiInstance.get(`/paramlists/custom_field/${modelo}/${modelo_id}/`, { params })

  return response.data
})

export const postCustomModelField = authValidationMiddleware(async (modelo, modelo_id, payload, params = {}) => {
  const response = await axiosApiInstance.post(`/paramlists/custom_field/${modelo}/${modelo_id}/`, payload, { params })

  return response.data
})
